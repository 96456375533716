import MATT from './assets/matt.jpg'
import SAORI from './assets/saori.jpg'
import MATT_2X from './assets/matt@2x.jpg'
import SAORI_2X from './assets/saori@2x.jpg'

const ALL = {
    MATT,
    SAORI,
    MATT_2X,
    SAORI_2X,
}

export default ALL
